<template>
	<a-space direction="vertical">
		<a-card :title="cardTitle">
			<a-form-model ref="form" :model="formdata" :rules="rules" v-bind="layout">
				<a-form-model-item label="标题" prop="title">
					<span v-if="readonly">{{ formdata.title }}</span>
					<a-input v-model="formdata.title" placeholder="请输入标题" v-else />
				</a-form-model-item>
				<a-form-model-item label="图片" prop="imageUrl" help="注: 建议尺寸:702x240">
					<!-- <a-avatar shape="square" :src="formdata.imageUrl" :size="128" v-if="readonly" /> -->
					<div class="details" v-if="readonly">
						<div class="image"><img :src="formdata.imageUrl" /></div>
					</div>
					<WsUpload :url="formdata.imageUrl" @success="e => (formdata.imageUrl = e.url)" v-else />
				</a-form-model-item>
				<a-form-model-item label="背景图片" prop="bgImageUrl">
					<!-- <a-avatar shape="square" :src="formdata.bgImageUrl" :size="128" v-if="readonly" /> -->
					<div class="details" v-if="readonly">
						<div class="image"><img :src="formdata.bgImageUrl" /></div>
					</div>
					<WsUpload :url="formdata.bgImageUrl" @success="e => (formdata.bgImageUrl = e.url)" v-else />
				</a-form-model-item>
				<a-form-model-item label="跳转类型" prop="type">
					<span v-if="readonly">{{ formdata.typeStr }}</span>
					<a-radio-group v-model="formdata.type" v-else>
						<a-radio :value="0">无跳转</a-radio>
						<a-radio :value="1">h5地址</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="跳转地址" prop="value" v-if="formdata.type == '1'">
					<span v-if="readonly">{{ formdata.value }}</span>
					<a-input v-model="formdata.value" placeholder="请输入跳转地址" v-else />
				</a-form-model-item>
				<a-form-model-item label="排序" prop="sort">
					<span v-if="readonly">{{ formdata.sort }}</span>
					<a-input v-model="formdata.sort" placeholder="请输入排序" v-else />
				</a-form-model-item>
				<a-form-model-item label="展示位置" prop="position">
					<span v-if="readonly">{{ formdata.positionStr }}</span>
					<a-radio-group v-model="formdata.position" v-else><a-radio :value="0">首页</a-radio></a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="状态" prop="status">
					<span v-if="readonly">{{ formdata.statusStr }}</span>
					<a-radio-group v-model="formdata.status" v-else>
						<a-radio :value="0">启用</a-radio>
						<a-radio :value="1">禁用</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item :wrapper-col="{ span: 18, offset: 4 }" v-if="!readonly"><a-button type="primary" @click="onSubmit">保存</a-button></a-form-model-item>
			</a-form-model>
		</a-card>
	</a-space>
</template>

<script>
import { copy } from '@/utils';
import WsUpload from '@/components/WsUpload.vue';

export default {
	components: {
		WsUpload
	},
	data() {
		return {
			formdata: {
				id: '',
				title: '',
				imageUrl: '',
				bgImageUrl: '',
				type: 0,
				value: '',
				sort: 1,
				position: 0
			},
			rules: {
				title: [
					{
						required: true,
						message: '请输入商品名称',
						trigger: 'blur'
					}
				],
				imageUrl: [
					{
						required: true,
						message: '请上传图片',
						trigger: 'blur'
					}
				],
				value: [
					{
						validator: (rule, value, callback) => {
							if (this.formdata.type == '1' && !value) {
								callback(new Error('请输入h5地址'));
							} else {
								callback();
							}
						},
						trigger: 'blur'
					}
				],
				sort: [
					{
						required: true,
						message: '请输入排序数值',
						trigger: 'blur'
					},
					{
						validator: (rule, value, callback) => {
							if (value && /^\d+$/.test(value)) {
								callback();
							} else {
								callback(new Error('请输入正确排序数值'));
							}
						},
						trigger: 'blur'
					}
				]
			},
			layout: {
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 18
				}
			}
		};
	},
	computed: {
		cardTitle() {
			return this.$route.meta.title || '';
		},
		readonly() {
			const routeName = this.$route.name;
			return routeName == 'bannerView';
		}
	},
	async mounted() {
		const id = this.$route.params.id;
		if (id) {
			const response = await this.$api.get(`/banner_detail/${id}`);
			if (response && response.code == 200) {
				this.formdata = Object.assign(response.data, {
					id
				});
			}
		}
	},
	methods: {
		onSubmit() {
			this.$refs.form.validate(async valid => {
				if (valid) {
					const response = await this.$api.post('/banner_update', this.formdata);
					if (response && response.code == 200) {
						this.$message.success('操作成功');
						setTimeout(() => {
							this.$router.back();
						}, 1000);
					}
				}
			});
		}
	}
};
</script>

<style lang="less">
.ant-cascader-menus,
.ant-select-dropdown {
	z-index: 1600;
}
.details {
	display: flex;
	flex-wrap: wrap;
	.image {
		position: relative;
		width: 128px;
		height: 128px;
		margin-right: 10px;
		margin-bottom: 10px;
		border: #eeeeee 1px dotted;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		object-fit: contain;
	}
}
</style>
